// #TODO: after addon release should be splitted to separate small components
import Vue from 'vue';
import 'whatwg-fetch';
import editCartMixin from 'shared/shop/mixins/common';

document.addEventListener('DOMContentLoaded', () => {
  Vue.component("shop-modal", {
    template: "#shop-modal-template"
  });

  Vue.component("shop-modal-info", {
    template: "#shop-modal-info"
  });

  Vue.component("shop-modal-message", {
    template: "#shop-modal-message-template"
  });

  let rootElement = document.querySelector('#shop-form');

  if(rootElement === null) { return; }

  let data = rootElement.dataset;

  let product = JSON.parse(data.product);
  let addons = JSON.parse(data.addons);
  let bundles = JSON.parse(data.bundles);
  let paymentPlans = JSON.parse(data.paymentPlans);
  let orderFormData = JSON.parse(data.orderForm);
  let shopSetting = JSON.parse(data.shopSetting);
  let promocode = JSON.parse(data.promocode);
  let localizedCountries = JSON.parse(data.localizedCountries);
  let localizedCheckoutLocales = JSON.parse(data.localizedCheckoutLocales);
  let locale = JSON.parse(data.locale);
  let activity = data.activity;

  new Vue({
    el: rootElement,
    mixins: [editCartMixin],
    data: {
      addons: addons,
      bundles: bundles,
      product: product,
      promocode: promocode,
      paymentPlans: paymentPlans,
      shopSetting: shopSetting,
      orderForm: orderFormData,
      currentPaymentPlan: paymentPlans[0],
      currencySign: data.currencySign,
      pricingUrl: data.pricingUrl,
      shoppingCartRootUrl: data.shoppingCartRootUrl,
      localizedCountries: localizedCountries,
      localizedCheckoutLocales: localizedCheckoutLocales,
      locale: locale,
      activity: activity,
    }
  });
});
